// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-institute-of-design-js": () => import("./../../../src/pages/fr/institute-of-design.js" /* webpackChunkName: "component---src-pages-fr-institute-of-design-js" */),
  "component---src-pages-fr-manufacturing-js": () => import("./../../../src/pages/fr/manufacturing.js" /* webpackChunkName: "component---src-pages-fr-manufacturing-js" */),
  "component---src-pages-fr-mobility-js": () => import("./../../../src/pages/fr/mobility.js" /* webpackChunkName: "component---src-pages-fr-mobility-js" */),
  "component---src-pages-fr-studio-js": () => import("./../../../src/pages/fr/studio.js" /* webpackChunkName: "component---src-pages-fr-studio-js" */),
  "component---src-pages-fr-textiles-of-senegal-js": () => import("./../../../src/pages/fr/textiles-of-senegal.js" /* webpackChunkName: "component---src-pages-fr-textiles-of-senegal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institute-of-design-js": () => import("./../../../src/pages/institute-of-design.js" /* webpackChunkName: "component---src-pages-institute-of-design-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-mobility-js": () => import("./../../../src/pages/mobility.js" /* webpackChunkName: "component---src-pages-mobility-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-textiles-of-senegal-js": () => import("./../../../src/pages/textiles-of-senegal.js" /* webpackChunkName: "component---src-pages-textiles-of-senegal-js" */)
}

